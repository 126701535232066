import ExcelJS from 'exceljs/dist/es5/exceljs.browser';
//import ExcelJS from "exceljs";
import { format } from 'date-fns';

export default {
    async getFileData(workbook, textData, count, deliveryDate) {
        let worksheet = null;
        const equipmentConfig = await this.getConfig(textData[2]);

        try {
            worksheet = workbook.getWorksheet('Data');

            this.clearWorksheet(worksheet);
        } catch {
            return null;
        }

        worksheet.getCell('A1').value = textData[0]; // Order #
        worksheet.getCell('A2').value = textData[2]; // Product Code
        worksheet.getCell('A3').value = textData[3]; // Model
        worksheet.getCell('A4').value = textData[4]; // Desc
        worksheet.getCell('A5').value = textData[6]; // Serial
        worksheet.getCell('A6').value = textData[7]; // Equipment ID
        worksheet.getCell('A7').value = textData[19]; // Pick Up Model

        worksheet.getCell('A8').value = format(deliveryDate, 'P'); // Delivery Date MM/DD/YYYY
        worksheet.getCell('A9').value = textData[10]; // Location
        worksheet.getCell('A10').value = textData[11]; // Department
        worksheet.getCell('A11').value = textData[13]; // Address
        worksheet.getCell('A12').value = textData[14]; // City
        worksheet.getCell('A13').value = textData[15]; // State
        worksheet.getCell('A14').value = textData[17]; // Contact

        // worksheet.getCell(
        //     'A15'
        // ).value = `PICK UP: ${textData[19]} - ${textData[20]} - ${textData[21]} - ${textData[22]}`; // Pick up

        if (textData[29] != undefined) {
            worksheet.getCell('A16').value = `MAC ADDRESS:\n\n${textData[29]}`; // Special Instructions
        }

        // Accessories:
        equipmentConfig.map((item, idx) => {
            let row = 16 + idx + 1;
            worksheet.getCell(`A${row}`).value = item.material;
            worksheet.getCell(`B${row}`).value = item.material;
            worksheet.getCell(`C${row}`).value = item.desc;
        });

        let locationName = `${textData[10].substring(0, 15)}`;
        locationName = locationName.split(/[\s]+/).join('-'); //| Replace Spaces with dashes

        const newFile = workbook.xlsx.writeBuffer().then(function(data) {
            const blob = new Blob([data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            return {
                name: `${locationName}-${textData[2]}-${count}.xlsx`,
                input: blob,
            };
        });

        return newFile;
    },
    async getData(workbook, textData, count, deliveryDate) {
        let worksheet = null;
        const equipmentConfig = await this.getConfig(textData[2]);

        try {
            worksheet = workbook.getWorksheet('DELIVERY TICKET');

            this.clearWorksheet(worksheet);
        } catch {
            return null;
        }

        worksheet.getCell('S2').value = textData[0]; // Order #
        worksheet.getCell('B16').value = textData[3]; // Product Code
        worksheet.getCell('E16').value = textData[3]; // Model
        worksheet.getCell('G16').value = textData[4]; // Desc
        worksheet.getCell('J16').value = textData[6]; // Serial
        worksheet.getCell('M16').value = textData[7]; // Equipment ID
        worksheet.getCell('J4').value = textData[19]; // Pick Up Model

        worksheet.getCell('S6').value = format(deliveryDate, 'P'); // Delivery Date MM/DD/YYYY
        worksheet.getCell('E8').value = textData[10]; // Location
        worksheet.getCell('E10').value = textData[11]; // Department
        worksheet.getCell('E9').value = textData[13]; // Address
        worksheet.getCell('E11').value = textData[14]; // City
        worksheet.getCell('H11').value = textData[15]; // State
        worksheet.getCell('I11').value = textData[16]; // Zip Code
        worksheet.getCell('E13').value = textData[17]; // Contact
        worksheet.getCell('E14').value = textData[18]; // Telephone

        // worksheet.getCell(
        //     'B38'
        // ).value = `PICK UP: ${textData[19]} - ${textData[20]} - ${textData[21]} - ${textData[22]}`; // Pick up

        if (textData[29] != undefined) {
            worksheet.getCell('M8').value = `MAC ADDRESS:\n\n${textData[29]}`;
        }

        // Accessories:
        equipmentConfig.map((item, idx) => {
            let row = 16 + idx + 1;
            worksheet.getCell(`C${row}`).value = item.material;
            worksheet.getCell(`E${row}`).value = item.material;
            worksheet.getCell(`G${row}`).value = item.desc;
        });

        let locationName = `${textData[10].substring(0, 15)}`;
        locationName = locationName.split(/[\s]+/).join('-'); //| Replace Spaces with dashes

        const newFile = workbook.xlsx.writeBuffer().then(function(data) {
            const blob = new Blob([data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            });
            return {
                name: `${locationName}-${textData[2]}-${count}.xlsx`,
                input: blob,
            };
        });

        return newFile;
    },
    async getWorkbook(workbookFile) {
        const tempfilereader = new FileReader();
        tempfilereader.readAsArrayBuffer(workbookFile);

        return new Promise((resolve) => {
            tempfilereader.onload = async () => {
                const buffer = tempfilereader.result;
                const workbook = new ExcelJS.Workbook();
                await workbook.xlsx.load(buffer);
                resolve(workbook);
            };
        });
    },
    clearWorksheet(worksheet) {
        worksheet.getCell('S2').value = ''; // Order #
        worksheet.getCell('B16').value = ''; // Product Code
        worksheet.getCell('E16').value = ''; // Model
        worksheet.getCell('G16').value = ''; // Desc
        worksheet.getCell('J16').value = ''; // Serial
        worksheet.getCell('M16').value = ''; // Equipment ID
        worksheet.getCell('J4').value = ''; // Pick Up Model
        worksheet.getCell('S6').value = ''; // Delivery Date

        worksheet.getCell('E8').value = ''; // Location

        worksheet.getCell('E9').value = ''; // Address

        worksheet.getCell('E10').value = ''; // Department

        worksheet.getCell('E11').value = ''; // City

        worksheet.getCell('H11').value = ''; // State
        worksheet.getCell('E13').value = ''; // Contact

        worksheet.getCell('B38').value = ''; // Pick up
        worksheet.getCell('M8').value = ''; // Special Instructions

        // Accessories:
        const rows = [16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28];
        rows.map((item) => {
            worksheet.getCell(`C${item}`).value = '';
            worksheet.getCell(`E${item}`).value = '';
            worksheet.getCell(`G${item}`).value = '';
            worksheet.getCell(`J${item}`).value = '';
            worksheet.getCell(`M${item}`).value = '';
            worksheet.getCell(`N${item}`).value = '';
            worksheet.getCell(`P${item}`).value = '';
            worksheet.getCell(`R${item}`).value = '';
        });

        return worksheet;
    },
    async getConfig(model) {
        const configs = {
            BP70C31: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
            ],
            BP70C36: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
            ],
            BP70C65: [
                { material: 'BPDE15', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
                { material: 'BPFX11', desc: 'Fax Kit' },
                { material: 'BPTR12', desc: 'RS Exit Tray' },
            ],
            BP70C31A: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
            ],
            MXB476WH: [],
            BP70M45: [
                { material: 'BPDE14', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
            ],
            BP70M31: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
                { material: 'BPFX11', desc: 'Fax Kit' },
                { material: 'BPTR12', desc: 'RS Exit Tray' },
            ],
            BP70M45A: [
                { material: 'BPDE14', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
            ],
            MXB476WHA: [
                { material: 'MXCS14N', desc: 'Drawer' },
                { material: 'MXCS14N', desc: 'Drawer' },
                { material: 'MXDS22N', desc: 'Stand' },
            ],
            BP70M45B: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
                { material: 'BPFX11', desc: 'Fax Kit' },
                { material: 'BPTR12', desc: 'RS Exit Tray' },
            ],
            BP70M31A: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
            ],
            BP70C31G: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
                { material: 'BPFX11', desc: 'Fax Kit' },
                { material: 'BPTR12', desc: 'RS Exit Tray' },
            ],
            BP70C31B: [
                { material: 'BPDE12', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
                { material: 'BPFX11', desc: 'Fax Kit' },
                { material: 'BPTR12', desc: 'RS Exit Tray' },
            ],
            MXC304WH: [
                { material: 'MXCS14N', desc: 'Drawer' },
                { material: 'MXDS22N', desc: 'Stand' },
            ],
            BP70C31C: [
                { material: 'BPDE14', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
                { material: 'MXPN14B', desc: 'Hole Punch' },
                { material: 'BPFX11', desc: 'Fax Kit' },
                { material: 'BPTR12', desc: 'RS Exit Tray' },
            ],
            MXB350P: [],
            BP70C31D: [
                { material: 'BPDE13', desc: 'Stand' },
                { material: 'BPFN11', desc: '50-sheet Finisher' },
            ],
            BP70C36B: [
                { material: 'BPDE13', desc: 'Stand' },
                { material: 'BPTU10', desc: 'Center Tray' },
            ],
            PNC751H: [
                { material: 'PNSPCi5W10C8GB', desc: 'Opt PC' },
                { material: 'PNSR780M', desc: 'Rolling Cart' },
            ],
        };

        return configs[model];
    },
};
