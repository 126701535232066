<template>
  <v-container>
    <v-container>
      <v-container>
        <v-card width="450px" outlined tile>
          <v-container>
            <p>Delivery Date:</p>
            <DatePick
              ref="deliveryDate"
              v-model="deliveryDate"
              dateTitle="Delivery Date: "
              :addedDays="addDays"
              @datePicked="deliveryDateChanged"
            />
          </v-container>
        </v-card>
      </v-container>
      <v-container>
        <v-card width="450px" outlined tile>
          <v-container>
            <v-file-input
              ref="blankEDF"
              v-model="blankEDF"
              @change="getEDF"
              show-size
              label="Blank EDF"
              accept=".xlsx"
              placeholder="Select .xlsx Delivery Ticket"
            ></v-file-input>
          </v-container>
        </v-card>
      </v-container>

      <v-container>
        <v-card width="450px" outlined tile>
          <v-container>
            <v-textarea
              name="dticket"
              v-model="tabText"
              :value="tabText"
              hint="Copy and Paste Delivery Details"
            />
          </v-container>
          <v-subheader v-show="currentNumber"
            >Ticket Count: {{ currentNumber }}</v-subheader
          >
        </v-card>
      </v-container>
    </v-container>
    <v-container>
      <v-btn
        class="ma-2"
        @click="getFile"
        :disabled="tabText === '' || blankEDF === null || deliveryDate === null"
        outlined
        color="primary"
        >Process Tickets</v-btn
      >
      <v-btn class="ma-2" @click="reset" outlined color="secondary"
        >Reset</v-btn
      >
    </v-container>
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script>
import { parseISO } from "date-fns";
import processText from "./processText";
import DatePick from "./DatePick.vue";
import { downloadZip } from "client-zip/index.js";
import { saveAs } from "file-saver";

export default {
  name: "app",
  components: {
    DatePick,
  },
  data() {
    return {
      addDays: 1,
      tabText: "",
      overlay: false,
      blankEDF: null,
      currentNumber: null,
      baseWorkbook: null,
      baseText: [],
      fileList: [],
      deliveryDate: null,
    };
  },
  methods: {
    async getEDF() {
      // Get completed EA Spreadsheet
      if (this.blankEDF != null) {
        this.overlay = true;
        this.baseWorkbook = await processText.getWorkbook(this.blankEDF);
        this.overlay = false;
      }
    },
    async getFile() {
      // if (this.blankEDF != null) {
      //   this.overlay = true;
      //   this.baseWorkbook = await processText.getWorkbook(this.blankEDF);
      // }

      this.baseText = this.tabText.split("\n").map((line) => line.split("\t"));

      let i;
      for (i = 0; i < this.baseText.length - 1; i++) {
        this.fileList.push(
          await processText.getData(
            this.baseWorkbook,
            this.baseText[i],
            i + 1,
            this.deliveryDate
          )
        );
        this.currentNumber = i + 1;
      }

      const content = await downloadZip(this.fileList).blob();
      saveAs(content, "Delivery Tickets.zip");

      this.overlay = false;
      this.processingDataFiles = false;
    },
    reset() {
      this.tabText = "";
      (this.baseWorkbook = null), (this.baseText = null), (this.fileList = []);
      this.currentNumber = null;
    },
    deliveryDateChanged(newDate) {
      this.deliveryDate = parseISO(newDate);
    },
  },
};
</script>
