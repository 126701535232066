<template>
  <v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
      <h4 v-bind="attrs" v-on="on">
        <v-menu
          v-model="delMenu"
          ref="delMenu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <span class="primary--text" v-on="on">{{ fullDelDate }}</span>
          </template>
          <v-date-picker
            v-model="delDate"
            elevation="3"
            @input="datePicked"
          ></v-date-picker>
        </v-menu>
        <!-- <span class="pl-2 text-caption font-italic text-secondary">
          In {{ daysFromNow }} Days
        </span> -->
      </h4>
    </template>
    <span>
      Click Date to Change
    </span>
  </v-tooltip>
</template>

<script>
import {
  parseISO,
  format,
  addBusinessDays,
  addMonths,
  differenceInBusinessDays,
  startOfMonth
} from "date-fns";


export default {
  props: {
    addedMonths: {
      type: Number,
      required: false,
      default: 0
    },
    addedDays: {
      type: Number,
      required: false,
      default: 0
    },
    dateTitle: {
      type: String,
      required: false,
      default: "Install: "
    },
    firstDayofMonth: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    delDate: new Date(),
    delMenu: false
  }),
  methods: {
    datePicked() {
      this.delMenu = false;
      this.$emit("datePicked", this.delDate);
    },
    formatDate(date) {
      if (!date) return null;
      return format(new Date(date), "MM/dd/yyyy");
    }
  },
  computed: {
    computedDelDate() {
      return this.formatDate(this.delDate);
    },
    daysFromNow() {
      return differenceInBusinessDays(parseISO(this.delDate), new Date());
    },
    fullDelDate() {
      return format(new Date(parseISO(this.delDate)), "MMM d, yyyy");
    }
  },
  watch: {
      delDate(){
        this.$emit('datePicked', this.delDate)
      }
  },
  created() {
    this.delDate = format(
      addBusinessDays(new Date(), this.addedDays),
      "yyyy-MM-dd"
    );
    this.delDate = format(
      addMonths(new Date(), this.addedMonths),
      "yyyy-MM-dd"
    )
    if(this.firstDayofMonth) {
      const startDate = format(startOfMonth(parseISO(this.delDate)), "yyyy-MM-dd")
      this.delDate = startDate
    }
    this.$emit("datePicked", this.delDate);
  }
};
</script>
